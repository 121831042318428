"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var saep_inicio_component_1 = require("app/pages/saep-inicio/saep-inicio.component");
var routes = [
    { path: '', component: saep_inicio_component_1.SaepInicio },
];
var SaepInicioModule = /** @class */ (function () {
    function SaepInicioModule() {
    }
    return SaepInicioModule;
}());
exports.SaepInicioModule = SaepInicioModule;
