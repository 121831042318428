"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Módulo com imports/re-exports comuns para módulos de páginas.
 * Facilita a modularização das páginas
 * (Ver uso em módulos individuais por módulos lazy de páginas: ex: /pages/usuario/usuario.module.ts)
 */
var SipepPagesCommonModule = /** @class */ (function () {
    function SipepPagesCommonModule() {
    }
    return SipepPagesCommonModule;
}());
exports.SipepPagesCommonModule = SipepPagesCommonModule;
