"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../node_modules/primeng/components/progressspinner/progressspinner.ngfactory");
var i2 = require("primeng/components/progressspinner/progressspinner");
var i3 = require("@angular/common");
var i4 = require("./saep-inicio.component");
var i5 = require("../../webtyped/api/inicio.service");
var i6 = require("../../services/busy-indicator-service/busy-indicator.service");
var i7 = require("../../services/contexto-usuario/contexto-usuario.service");
var i8 = require("../../webtyped/api/usuario.service");
var i9 = require("@angular/platform-browser");
var i10 = require("@angular/router");
var styles_SaepInicio = [];
var RenderType_SaepInicio = i0.ɵcrt({ encapsulation: 2, styles: styles_SaepInicio, data: {} });
exports.RenderType_SaepInicio = RenderType_SaepInicio;
function View_SaepInicio_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p-progressSpinner", [["class", "mx-auto d-block"]], null, null, null, i1.View_ProgressSpinner_0, i1.RenderType_ProgressSpinner)), i0.ɵdid(2, 49152, null, 0, i2.ProgressSpinner, [], null, null)], null, null); }
function View_SaepInicio_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "p-5 mt-4 jumbotron"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Bem-Vindo ao SIPEP"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "container"], ["style", "margin-top: 50px;"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textoHomeFinal; _ck(_v, 3, 0, currVal_0); }); }
function View_SaepInicio_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SaepInicio_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SaepInicio_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loaded; _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_SaepInicio_0 = View_SaepInicio_0;
function View_SaepInicio_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "saep-inicio", [], null, null, null, View_SaepInicio_0, RenderType_SaepInicio)), i0.ɵdid(1, 245760, null, 0, i4.SaepInicio, [i5.InicioService, i6.BusyIndicatorService, i7.ContextoUsuarioService, i8.UsuarioService, i9.DomSanitizer, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SaepInicio_Host_0 = View_SaepInicio_Host_0;
var SaepInicioNgFactory = i0.ɵccf("saep-inicio", i4.SaepInicio, View_SaepInicio_Host_0, {}, {}, []);
exports.SaepInicioNgFactory = SaepInicioNgFactory;
